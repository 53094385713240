<template>
  <div>
    <div v-if="show" id="invoice" style="background-color: #FFFFFF;text-align: initial;padding: 30px 15px;">
      <div class="row mb-5">
        <div class="col-3">
            <p class="text-center" style="padding: 2px 7px;border: 1px solid #000000;border-radius: 5px">{{ data.amount }} {{ data.currency_name }}</p>
        </div>
        <div class="col-6 text-center">
          <h2 style="border-bottom: 1px double #000000;padding-bottom: 15px;">{{ $t('expenses_incomes_print.received_voucher') }}</h2>
        </div>
        <div class="col-3">
          <p class="text-center"><b>{{ $t('expenses_incomes_print.date') }}: {{ today_date }}</b></p>
        </div>


      </div>

      <!--      <table class="table table-bordered">-->
      <!--        <tr>-->
      <!--          <td>-->
      <!--            <p>{{$t('expenses_incomes_print.expenses')}}</p>-->
      <!--            <p>{{$t('expenses_incomes_print.number')}}: {{data.code}}</p>-->
      <!--          </td>-->
      <!--          <td>-->
      <!--            <p>{{company_name}}</p>-->
      <!--          </td>-->
      <!--        </tr>-->
      <!--      </table>-->
      <p><b>{{ $t('expenses_incomes_print.received_from_mr') }}:</b> {{ data.obj_name }}</p>
      <p><b>{{ $t('expenses_incomes_print.amount_of') }}:</b> {{ data.amount }} {{ data.currency_name }} </p>
      <p>{{ data.payment_method_name }} <b>{{ $t('expenses_incomes_print.on') }}</b> {{ data.type_type == 'Treasuries' ? data.treasury.name : '' }}{{ data.type_type == 'Banks' ? data.bank.name : '' }} </p>
      <p><b>{{ $t('expenses_incomes_print.on_the_date') }}:</b> {{ data.expenses_date }} </p>
      <p><b>{{ $t('expenses_incomes_print.and_that_for') }}:</b> {{ data.notes }} </p>


      <div class="row mt-5">
        <div class="col-4">
          <h6 class="text-center">{{ $t('expenses_incomes_print.recipient_signature') }}</h6>
          <p class="text-center mt-5">...............................</p>
        </div>
        <div class="col-4">
          <h6 class="text-center">{{ $t('expenses_incomes_print.signature_of_the_treasurer') }}</h6>
          <p class="text-center mt-5">...............................</p>
        </div>
        <div class="col-4">
          <h6 class="text-center">{{ $t('expenses_incomes_print.signature_of_the_manager') }}</h6>
          <p class="text-center mt-5">...............................</p>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import {jsPDF} from "jspdf";
import jsPdfExport from "@/core/config/jsPdfExport";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "incomePrint",
  data() {
    return {
      mainRoute: 'finances/expenses_incomes',
      data: {},
      company_name: null,
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      today_date: null,
    }
  },
  methods: {
    ...timeZoneStructure,
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data = response.data.data;
        this.show = true;
      });
    },
    printInvoice() {
      // let tableId = 'invoice'
      // let divToPrint = document.getElementById(tableId);
      // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divToPrint.outerHTML);
      // i18nService.getActiveLanguage();
      // setTimeout(() => {
      //   window.print();
      //   window.close();
      // }, 100)
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
  },
  mounted() {
    // this.today_date = new Date().toISOString().substr(0, 10);
    this.getTimeZone().then((res)=>{
      this.today_date = res;
    });
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
  }
}
</script>

<style >
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
</style>